<template>
  <div class="yourself" id="show">
    <div class="topSelf fl-center">
      <div class="topContent">
        <img class="imgphoto" :src="user.head" alt="" />
        <div class="leftTitle">
          <p class="title">{{user.nickname}}</p>
          <p class="text fl-between">
            <img :src="user.identity === 1 ? imguser : imgcoach" alt="" style="margin-right:11px">
            <span>佣金: ￥<span class="money">{{user.money}}</span></span>
          </p>
        </div>
      </div>
    </div>
    <div class="favorite">
      <div class="box fl-between" @click="gonext(1)">
        <div class="left fl-between">
          <img class="icon" src="../assets/image/baoming.png" alt="" />
          <p class="title">我的报名</p>
        </div>
        <van-icon name="arrow" />
      </div>
      <div class="line"></div>
      <p class="bmtext">{{ status ? '已报名' : '未报名' }}</p>
    </div>
    <div class="box fl-between" @click="gonext(2)">
      <div class="left fl-between">
        <img class="icon" src="../assets/image/tuiguang.png" alt="" />
        <div>
          <p class="languageOther">ངའི་ཁྱབ་སྤེལ།</p>
          <p class="title">我的推广</p>
        </div>
      </div>
      <van-icon name="arrow" />
    </div>
    <div class="line"></div>
    <div class="box fl-between" @click="gonext(3)">
      <div class="left fl-between">
        <img class="icon" src="../assets/image/haibao.png" alt="" />
        <div>
          <p class="languageOther">ངའི་བརྡ་ཁྱབ།</p>
          <p class="title">我的海报</p>
          
        </div>
      </div>
      <van-icon name="arrow" />
    </div>
    <div class="line"></div>
    <div class="box fl-between" @click="gonext(4)">
      <div class="left fl-between">
        <van-badge  :content="noticeNums === 0 ? '' : noticeNums" max="99">
          <img class="icon" src="../assets/image/mydidi.png" alt="" />
        </van-badge>
        <div>
          <p class="languageOther">ངའི་གནས་ཚུལ།</p>
          <p class="title">我的消息</p>
          
        </div>
      </div>
      <van-icon name="arrow" />
    </div>
    <div class="line"></div>
    <div class="box fl-between" @click="gonext(5)">
      <div class="left fl-between">
        <img class="icon" src="../assets/image/order.png" alt="" />
        <div>
          <p class="languageOther">ངའི་ཉོ་ཐོ།</p>
          <p class="title">我的订单</p>
          
        </div>
      </div>
      <van-icon name="arrow" />
    </div>
    <div class="line"></div>
    <div class="box fl-between" @click="gonext(6)">
      <div class="left fl-between">
        <img class="icon" src="../assets/image/language.png" alt="" />
        <div>
          <p class="languageOther">སྐད་རིགས་འདེམ་པ།</p>
          <p class="title">语言选择</p>
          
        </div>
      </div>
      <van-icon name="arrow" />
    </div>
    <div class="line"></div>
    <a class="kefu1" :href="`tel:${phone}`">ཞབས་ཞུའི་ཁ་པར།: {{phone}}</a>
    <a class="kefu" :href="`tel:${phone}`">客服热线：{{phone}}</a>
  </div>
</template>

<script>
import { getSign } from '@/api/sigin'
import { noticeNum, getDetail, getmoey, getUser } from '@/api'
import { Notify } from 'vant'
import watermark from '@/utils/watermark'
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      bms: "ཁྱེད་རང་མིང་ཐོ་བཀོད་མེད་།",
      status: false,
      noticeNums: 5,
      user: {},
      imguser: require('../assets/image/user.png'),
      imgcoach: require('../assets/image/coach.png'),
      phone: '',
      type: true
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getstatus()
    this.getnum()
    getDetail().then(res => {
      this.phone = res.data.customerPhone
    })
    this.getmoneys()
  },
  mounted() {
    this.getUserIfo()
    // this.user = JSON.parse(localStorage.getItem('login')) ? JSON.parse(localStorage.getItem('login')) : {head: '', nickname: ''}
    watermark.set('多杰泰考试', '')
  },
  methods: {
    getUserIfo() {
      getUser().then(res => {
        this.user = res.data
      })
    },
    getmoneys() {
      getmoey().then(res => {
        if (res.data) {
          this.user.money = (res.data/100).toFixed(2)
        } else {
          this.user.money = 0.00
        }
      })
    },
    getnum () {
      noticeNum().then(res => {
        this.noticeNums = res.data
      })
    },
    getstatus() {
      getSign().then(res => {
        if (res.httpStatus === 200) {
          if (res.data) {
            this.type = true
            this.status = true
          }
        } else {
          this.type = false
        }
      })
    },
    gonext(e) {
      if (!this.type) {
        Notify({ type: 'danger', message: `请先关注公众号`})
        return false
      } else {
        switch (e) {
          case 1:
            this.$router.push('/register')
            break;
          case 2:
            this.$router.push('/popularize')
            break;
          case 3:
            this.$router.push('/myplaybill')
            break;
          case 4:
            this.$router.push('/information')
            break;
          case 5:
            this.$router.push('/myorder')
            break;
          case 6:
            this.$router.push('/languageChoose')
          default:
            break;
        }
      }
    }
  },
};
</script>

<style scoped lang="scss">
.yourself {
  display: flex;
  flex-direction: column;
  align-items: center;
  .topSelf {
    width: 100%;
    height: 157px;
    background: linear-gradient(360deg, #5cb9ff 0%, #267cfa 100%);
    .topContent {
      display: flex;
      align-items: center;
      width: 295px;
      height: 60px;
      .leftTitle {
        .title {
          font-size: 20px;
          font-family: SourceHanSansSC-Bold, SourceHanSansSC;
          font-weight: bold;
          color: #fcfbfb;
          line-height: 29px;
        }
        .text {
          font-size: 18px;
          font-family: SourceHanSansSC-Medium, SourceHanSansSC;
          font-weight: 600;
          color: #fcfbfb;
          line-height: 17px;
          margin-top: 6px;
          img{
            width: 60px;
            height: 14px;
          }
        }
        
      }
      .imgphoto {
        margin-right: 20px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }
  }
  .box {
    width: 304px;
    height: 24px;
    &:nth-child(3) {
      margin-top: 23px;
      margin-bottom: 8px;
    }
    &:nth-child(5) {
      margin-top: 16px;
      margin-bottom: 6px;
    }
    &:nth-child(7) {
      margin-top: 16px;
      margin-bottom: 6px;
    }
    &:nth-child(9) {
      margin-top: 16px;
      margin-bottom: 6px;
    }
    &:nth-child(11) {
      margin-top: 16px;
      margin-bottom: 6px;
    }
    .left {
      height: 24px;
      .icon {
        width: 24px;
        height: 24px;
      }
      .title {
        font-size: 12px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 400;
        color: #171717;
        line-height: 26px;
        padding-left: 20px;
      }
      .languageOther{
        font-size: 18px;
        font-family: SourceHanSansSC-Regular, SourceHanSansSC;
        font-weight: 600;
        color: #171717;
        line-height: 26px;
        padding-left: 20px;
      }
    }
    i {
      font-size: 18px;
      font-weight: 600;
    }
  }
  .line {
    width: 304px;
    height: 1px;
    border-top: 1px solid #f3f3f3;
    margin-top: 10px;
  }
  .bmtext {
    padding-top: 13px;
    font-size: 16px;
    font-family: SourceHanSansSC-Regular, SourceHanSansSC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.1);
    line-height: 24px;
  }
  .favorite {
    width: 307px;
    height: 67px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(160, 160, 160, 0.4);
    border-radius: 4px;
    margin-top: -25px;
    padding: 22px 26px;
  }
  .kefu {
    width: 304px;
    font-size: 14px;
    font-family: SourceHanSansSC-Medium, SourceHanSansSC;
    font-weight: 500;
    color: #171717;
    line-height: 26px;
    padding-top: 14px;
  }
  .kefu1{
    width: 304px;
    font-size: 18px;
    font-family: SourceHanSansSC-Medium, SourceHanSansSC;
    font-weight: 600;
    color: #171717;
    line-height: 26px;
    padding-top: 14px;
  }
}
</style>
